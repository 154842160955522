import axios, { AxiosInstance } from 'axios';
import HttpException from '../http-exception';
const APP_URL = {
  BASE_URL: process.env.VUE_APP_BASE_URL || 'http://localhost:8080',
  API_URL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  API_KEY: process.env.VUE_APP_API_KEY || '299d09a01c5811ec917be1e6b38a3730',
  // SANDBOX: true,
  SANDBOX:
    typeof process.env.VUE_APP_SANDBOX === 'string'
      ? process.env.VUE_APP_SANDBOX === 'true'
      : process.env.VUE_APP_SANDBOX,
};

const Api: AxiosInstance & { setToken?: (token: string) => void } =
  axios.create({
    baseURL: APP_URL.API_URL,
    timeout: 10000,
    responseType: 'json',
  });

Api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    console.log('location', window.location);
    if (
      error.response &&
      error.response.status == 401 &&
      !window.location.pathname.startsWith('/auth/login') &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/signup-invitation'
    )
      window.location.href = '/auth/login?redirect=' + window.location.pathname;
    return Promise.reject(error);
  },
);

if (localStorage.getItem('agriwallet.access.token'))
  Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
    'agriwallet.access.token',
  )}`;

Api.setToken = (token: string) => {
  localStorage.setItem('agriwallet.access.token', token);
  Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export default Api;

export { APP_URL, Api, HttpException };
