import { defineStore } from 'pinia';
import allProducteurs from '@/data/producteurs.json';
import { IErrorData, IProducteur, IUser, PrimaryKey } from '@/interfaces/index';
import Api, { HttpException } from '@/api';
import { useUsersStore } from './users/user-store';
import data from '@/data';

export const useProducteur = defineStore('producteurStore', {
  state: () => {
    return {
      producteurs: [] as IProducteur[],
    };
  },
  getters: {
    getAllProducteurs: (state) => {
      return state.producteurs;
    },
  },

  actions: {
    async fetchAll(params: any = {}) {
      const response = await Api.get('grower', { params });
      this.producteurs = response.data;
      return response.data
    },

    async fetchProductorsWithoutOp(params: any = {}) {
      const response = await Api.get('grower/no-cooperative', { params });
      return response.data
    },

    async fetchOne(id: string) {
      try {
        const response = await Api.get(`grower/${id}`);

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async fetchOpGrowers(id: string,params: any = {}) {
      try {
        const response = await Api.get(`farmer-organization/${id}/growers`,{ params });

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async create(id: PrimaryKey,data: any) {
     try {
        console.log("grower data:",data)
        const response = await Api.post(`/grower/cluster/${id}`, data);
        this.producteurs = response.data;
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        console.log("error message",error.response)
        let errorData: IErrorData = {
          isOk: false,
          message: error.response.data.message,
        };
      
        return errorData;
      }
    },
    async create2(data: any) {
      console.log('data --->', data);
      try {
        const response = await Api.post('/grower', data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async update(dataUser: any, idUser: string) {
      try {
        const response = await Api.patch(`grower/${idUser}`, dataUser);
        //this.producteurs = response.data;
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        
        let errorData: IErrorData = {
          isOk: false,
          message: error.message,
        };
       
        return errorData;
      }
    },

    async delete(id: string) {
      try {
        console.log("grower before before",this.producteurs)
        const response = await Api.delete(`/grower/${id}`);
        console.log("grower before",this.producteurs)
        this.producteurs = this.producteurs.filter((prod) => prod.id != id);
        console.log("grower after",this.producteurs)
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: 'Une erreur inattendue est survenue',
        };
        return errorData;
      }
    },


    async searchProductor(search: string) {
      if (search.length === 0) {
        return [];
      }
      console.log('producteur', this.producteurs);
      const filteredList = this.producteurs.filter((prod: IProducteur) => {
        return (
          prod.user.firstname.toLowerCase().includes(search.toLocaleLowerCase()) ||
          prod.user.lastname.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          prod.user.phone.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      });

      return filteredList;
    },

   
  },
});
