import { DateTime } from 'luxon';
import {
  IContratStatus,
  ITitleValue,
  IClusterStatus,
  IGrowerRequestStatus,
  ITransactionType,
  ISupplyStatus,
} from '@/interfaces';

export function formatDate(
  date: Date | string,
  format: string | string[] = 'DATE_FULL',
) {
  return luxonFormateDate(date, format);
}
export function formatDateWithA(date: Date) {
  const datef = luxonFormateDate(date, 'DATETIME_SHORT').split(' ');
  return datef[0] + ' à ' + datef[1];
}

export function numStr(aa: number, b: string = ' ') {
  let a = '' + aa;
  b = b || ' ';
  var c = '',
    d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substring(1);
  }
  for (var i = a.length - 1; i >= 0; i--) {
    c = d != 0 && d % 3 == 0 ? a[i] + b + c : a[i] + c;
    d++;
  }
  //"10000".replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 " //# => "10,000"

  return c;
}

const FORMAT: any = {
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
  DATETIME_MED: DateTime.DATETIME_MED,
  DATETIME_FULL: DateTime.DATETIME_FULL,
  DATE_FULL: DateTime.DATE_FULL,
  DATE_HUGE: DateTime.DATE_HUGE,
  DATE_MED: DateTime.DATE_MED,
  DATE_SHORT: DateTime.DATE_SHORT,
};

function luxonFormateDate(date: any, format: string | string[] = 'DATE_FULL') {
  const d = new Date(date);
  if (Array.isArray(format)) {
    return DateTime.fromJSDate(d).toFormat(format.join(' '));
  } else {
    return DateTime.fromJSDate(d).toLocaleString(FORMAT[format]);
  }
}
const defaultContactOption: Partial<
  Record<
    IContratStatus | IGrowerRequestStatus | IClusterStatus | ISupplyStatus,
    string
  >
> = {
  INACTIVE: 'Inactif',

  CONFIRMED: 'Confirmée',
  DELIVERED: 'Livraison disponible',
  COMPLETED: 'Terminé',
  CANCELED: 'Expiré',
  OUTTIME: 'Hors Délais',

  NEW: 'Nouveau',
  INIT: 'Nouveau',
  DONE: 'Validée',

  CREATED: 'En attente',
  ACCEPTED: 'Accepté',
  REJECTED: 'Rejetée',

  SUCCESS: 'Succès',

  PENDING: 'En attente',
};

export function getContractSelectOption(
  contractsOptions: Partial<
    Record<IContratStatus, string>
  > = defaultContactOption,
): ITitleValue[] {
  const options = [] as ITitleValue[];
  Object.entries(contractsOptions).forEach(([value, title]) => {
    if (title) {
      options.push({ title, value });
    }
  });
  return options;
}
export function getTransactionType(type: ITransactionType) {
  const options = {
    DEBIT: 'Débit',
    TRANSFER: 'Transfert',
    REFUND: 'Remboursement',
    STANDARD: 'Standard',
    SUPPLY: 'Approvisionnement',
  };
  var response = '';

  response = options[type] as string;
  return response;
}
export function getStatusLabel(
  status:
    | IContratStatus
    | IClusterStatus
    | IGrowerRequestStatus
    | ISupplyStatus,
  options = defaultContactOption,
) {
  const _options = {
    ...defaultContactOption,
    ...options,
  };
  const response = {
    type: 'success' as 'success' | 'danger' | 'warning',
    title: '',
  };

  switch (status) {
    case 'CANCELED':
    case 'CANCELED':
    case 'OUTTIME':
    case 'INACTIVE':
    case 'REJECTED':
      response.type = 'danger';
      break;
    case 'PENDING':
    case 'CREATED':
    case 'INIT':
      response.type = 'warning';
      break;
    case 'DELIVERED':
    case 'COMPLETED':
    case 'SUCCESS':
    case 'DONE':
      response.type = 'success';
    case 'NEW':
      response.type = 'success';
    case 'CONFIRMED':
      response.type = 'success';
      break;

    default:
      break;
  }
  response.title = _options[status] as string;
  return response;
}

export function normalizeApiResponse(response: any) {
  const isArray = Array.isArray(response);
  console.log('response data ---> ', response);
  const data = isArray ? response : response.data;
  const count = isArray
    ? 20
    : typeof response.count === 'number'
    ? response.count
    : 20;

  return { data, count };
}

export function notifyWithSound() {
  const audio = new Audio('notification.wav');
  audio.play();
}

export function formatMoney(n : any, currency = false) {
  n = String(n)
    .replace(/\D+/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${n}`;
}
