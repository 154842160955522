import Auth from "@/auth";
import { defineStore } from "pinia";
import Api, { HttpException } from "@/api/index";
import {
  IErrorData,
  PrimaryKey,
  IContratTransport,
  ITransporteur,
  IProducteur,
} from "@/interfaces";

export const authStore = defineStore("auth", {
  state: () => {
    return {
      auth: new Auth(null) as Auth,
      username: "",
      balance: 0,
      carrierContracts: [] as Array<IContratTransport>,
      transporteurs: [] as ITransporteur[],
      producteurs: [] as IProducteur[],
    };
  },
  getters: {
    setAuth: (state) => {
      return (data: any) => {
        state.auth = new Auth(data);
        console.log("dddd");

        console.log(state.auth.isAuthenticated() && Api.setToken);

        if (state.auth.isAuthenticated() && Api.setToken) {
          console.log("setAuth in if condition", data);
          Api.setToken(data.access_token);
        }
        return state.auth;
      };
    },
    getAuth: (state) => {
      return state.auth;
    },
    getAllCarrierContrats: (state) => {
      return state.carrierContracts;
    },
  },
  actions: {
    async login(credentials: { username: string; password: string }) {
      try {
        console.log("credentials", credentials);
        const response = await Api.post("auth/login", credentials);

        this.setAuth(response.data);
        const token = response.data.access_token;

        console.log("response ---> ", response);

        sessionStorage.setItem("user-role", response.data.user.role.code);
        sessionStorage.setItem("user-id", response.data.user.id);

        console.log("token token", token);
        if (Api.setToken) Api.setToken(token);
        return {
          isOk: true,
          message: "",
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: "Une erreur inattendue est survenue",
        };
        switch (error.response.status) {
          case 401:
            errorData.message = "Vos identifiants sont incorrectes";
            break;
          case 406:
            errorData.message = "Votre compte n'a pas encore été activé";
            break;
        }
        return errorData;
      }
    },

    async register(data: any) {
      try {
        const response = await Api.post("/users/validation/signup", data);
        return {
          isOk: true,
          message: "",
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: "Une erreur inattendue est survenue",
        };
        switch (error.response.status) {
          case 403:
            errorData.message =
              "Le token est invalide.Contactez l'administrateur.";
            break;
        }
        return errorData;
      }
    },

    async requestPasswordReset(data: { email: string }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Api.post(
            `users/forgot-password/${data.email}`,
            data
          );
          resolve(response.data);
        } catch (error: any) {
          reject(new HttpException(error));
        }
      });
    },
    async changePasssword(data: any) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Api.post(`users/reset-password`, data);
          resolve(response.data);
        } catch (error: any) {
          reject(new HttpException(error));
        }
      });
    },

    async requestRegister(data: any) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Api.post(`users/reset-password`, data);
          resolve(response.data);
        } catch (error: any) {
          reject(new HttpException(error));
        }
      });
    },

    async retryRequestRegister(data: { email: string }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Api.post(
            `users/forgot-password/${data.email}`,
            data
          );
          resolve(response.data);
        } catch (error: any) {
          reject(new HttpException(error));
        }
      });
    },

    async logout() {
      return new Promise((resolve, reject) => {
        // TODO: make logout request
        if (localStorage.getItem("agriwallet.access.token")) {
          localStorage.removeItem("agriwallet.access.token");
        }
        // this.setAuth(null)
        location.reload();
        resolve(true);
      });
    },

    async getDeliveryContracts(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(
          `contracts/smarts/${id}/LIVRAISON`,
          config
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getInputAdvancedContracts(id: PrimaryKey = null, params: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`contracts/smarts/${id}/LIVRAISON`, {
          params,
        });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCarrierContracts(id: PrimaryKey = null, params: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(
          `contracts/carriage-contract/user/${id}`,
          { params }
        );
        this.carrierContracts = response.data;
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCarrierContract(id: PrimaryKey = null, params: any = {}) {
      try {
        const response = await Api.get(`carrier/contracts/${id}`, { params });

        // return response.data;
        return { data: response.data.data, dataSize: response.data.count };
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getGrowerContract(id: PrimaryKey = null, params: any = {}) {
      try {
        const response = await Api.get(`grower/contracts/${id}`, { params });
        // return response.data;
        return { data: response.data.data, dataSize: response.data.count };
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCooperatives(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getOrganizationId();
      }
      try {
        const response = await Api.get(
          `organization/${id}/cooperative`,
          config
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getGrowers(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getOrganizationId();
      }
      try {
        const response = await Api.get(`organization/${id}/growers`, config);
        this.producteurs = response.data.data;
        console.log("producteurs debug", this.producteurs);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getGrowerAskFertilizer(id: PrimaryKey = null, config: any = {}) {
      // if (!id) {
      //   id = this.auth.getOrganizationId();
      // }
      try {
        const response = await Api.get(
          `/input-provider/growers-request/${id}`,
          config
        );
        console.log("getGrowerAskFertilizer", response);
        return {
          data: response.data.data.values,
          count: response.data.data.count,
        };
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async findGrowerByCode(code: any, config: any = {}) {
      try {
        const response = await Api.get(
          `/input-provider/find-by-code/${code}`,
          config
        );
        console.log("producteurs debug", response.data);
        return {
          isOk: true,
          response: response.data,
        };
      } catch (error: any) {
        switch (error.response.status) {
          case 404:
            return {
              isOk: false,
              reponse: "",
            };
        }
      }
    },

    async confirmDelivery(id: string) {
      try {
        const response = await Api.post(`/input-provider/make-delivery/${id}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getAllGrowers(id: PrimaryKey = null, config: any = {}) {
      try {
        const response = await Api.get(`/grower`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getGrowersByCluster(id: PrimaryKey = null, config: any = {}) {
      try {
        const response = await Api.get(`/grower/cluster/${id}`, {
          params: config,
        });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getAllCarriers(id: PrimaryKey = null, config: any = {}) {
      try {
        const response = await Api.get(`/carrier`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCarriers(id: PrimaryKey = null, params: any = {}) {
      if (!id) {
        id = this.auth.getOrganizationId();
      }
      try {
        const response = await Api.get(`organization/${id}/carriers`, {
          params,
        });
        this.transporteurs = response.data.data;
        console.log("transporteurs debug", this.transporteurs);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getStats(id: PrimaryKey = null, params = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`contracts/stats/${id}`, { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async executeDeliveryContract(data: any) {
      try {
        const response = await Api.post(`contracts/run-contracts`, data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getTransactions(id: PrimaryKey = null, params: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`transaction/user/${id}`, { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getFinancements(id: PrimaryKey = null, params: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`financement/user/${id}`, { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getUsers(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getOrganizationId();
      }
      try {
        const response = await Api.get(`organization/${id}/users`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getUser(id: PrimaryKey = null, config: any = {}) {
      try {
        const response = await Api.get(`users/${id}`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getAllUsers(config: any = {}) {
      console.log("all users", config);
      try {
        const response = await Api.get(`/users`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getUsersByCluster(id: PrimaryKey, config: any = {}) {
      console.log("all users", config);
      try {
        const response = await Api.get(`/users/cluster/${id}`, {params : config});
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getAllIndustrials(search: string) {
      try {
        const response = await Api.get(`/users/industrials?q=${search}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getDepartments() {
      try {
        const response = await Api.get("departments");
        return response.data.data.map(
          (item: { code: string; name: string }) => {
            return {
              title: item.name,
              value: item.code,
            };
          }
        );
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCommons(code?: string) {
      try {
        const response = await Api.get(`commons/${code}`);
        console.log("commons", response.data.data);
        return response.data.data.map(
          (item: { code: string; name: string }) => ({
            title: item.name,
            value: item.code,
          })
        );
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCommonName(code: string) {
      try {
        const response = await Api.get(`common/${code}`);
        return response.data.data.name;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getBoroughName(code: string) {
      try {
        const response = await Api.get(`borough/${code}`);
        return response.data.data.name;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getDistrictName(code: string) {
      try {
        const response = await Api.get(`district/${code}`);

        return response.data.data.name;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getBoroughs(departmentCode: string, commonCode: string) {
      try {
        const response = await Api.get(
          `boroughs/${departmentCode}/${commonCode}`
        );
        // console.log("boroughs", response.data.data);
        return response.data.data.map(
          (item: { code: string; name: string }) => ({
            title: item.name,
            value: item.code,
          })
        );
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getDistricts(
      departmentCode: string,
      commonCode: string,
      boroughCode: string
    ) {
      try {
        const response = await Api.get(
          `districts/${departmentCode}/${commonCode}/${boroughCode}`
        );
        console.log("districts", response.data.data);
        return response.data.data.map(
          (item: { code: string; name: string }) => ({
            title: item.name,
            value: item.code,
          })
        );
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCampagnes(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`/clusters`, { params: config });
        this.carrierContracts = response.data;
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getCampagnesByCluster(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`/clusters/cluster/${id}`, {
          params: config,
        });

        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    //toutes les campagnes d'un industriel
    async getIndustrialCampagnes(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(`/clusters/industrial/${id}`, {
          params: config,
        });
        this.carrierContracts = response.data;
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
    //recuperer les infos d"une campagne de l'industriel
    async getIndustrialCampagne(
      id: PrimaryKey = null,
      idInd: PrimaryKey = null,
      config: any = {}
    ) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(
          `/clusters/${id}/industrial/${idInd}`,
          config
        );
        this.carrierContracts = response.data;
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async bannish(
      id: PrimaryKey = null,
      growerId: PrimaryKey = null,
      config: any = {}
    ) {
      if (!id) {
        id = this.auth.getUserId();
      }
      try {
        const response = await Api.get(
          `/users/${id}/bannish-grower/${growerId}`,
          config
        );
        this.carrierContracts = response.data;
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getAreaInfo(
      { area, clusterId }: { area: number; clusterId?: PrimaryKey },
      config: any = {}
    ) {
      if (!clusterId) {
        clusterId = this.auth.getClusterId();
      }
      try {
        const response = await Api.get(
          `/clusters/get-area-info/${clusterId}/${area}`,
          { params: config }
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getOrganizations(id: PrimaryKey = null, config: any = {}) {
      if (!id) {
        id = this.auth.getOrganizationId();
      }
      try {
        const response = await Api.get(
          `organization/${id}/cooperative`,
          config
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
    async toggleUserAccount(id: PrimaryKey = null) {
      try {
        const response = await Api.get(`users/activate-account/${id}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async sendMoney(data: any) {
      try {
        const response = await Api.post("/wallet/make-transfer", data);
        return {
          isOk: true,
          message: "",
        };
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async retriveMoney(id: PrimaryKey = null, data: any) {
      try {
        const response = await Api.post(`/wallet/make-debit/${id}`, data);
        return {
          isOk: true,
          message: "",
        };
      } catch (error: any) {
        throw error
      }
    },

    async fetchBalance() {
      try {
        const response = await Api.get(`wallet/stats`);
        this.balance = response.data.balance;

        return this.balance;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getWalletStats(id: string, year: string) {
      console.log("year", year);
      try {
        const response = await Api.get(`wallet/stats/${id}/${year}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async downloadGrowers(userId:string,config: any = {}) {
      open(Api.defaults.baseURL + "/grower/download/" + userId, "_blank");
      // try {
      //   const response = await Api.get(`/grower/download`, config);
      //   return response.data;
      // } catch (error: any) {
      //   throw new HttpException(error);
      // }
    },
    async downloadFarmerOrganizations(id: PrimaryKey, config: any = {}) {
      open(
        Api.defaults.baseURL + `/farmer-organization/download/${id}`,
        "_blank"
      );
      // try {
      //   const response = await Api.get(`/grower/download`, config);
      //   return response.data;
      // } catch (error: any) {
      //   throw new HttpException(error);
      // }
    },
  },
});
